<template>
    <v-card>

        <v-card-title>
            Пользователи
        </v-card-title>

        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            E-mail
                        </th>
                        <th class="text-left">
                            Был онлайн
                        </th>
                        <th>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="user in users"
                        :key="user.id"
                    >
                        <td>{{ user.email }}</td>
                        <td>
                            <template v-if="user.role == 'minor'">
                                {{ user.time }}
                            </template>
                        </td>
                        <td>
                            <v-btn
                                v-if="user.role == 'minor' && role == 'major'"
                                icon
                                @click.stop="openDeleteDialog(user)"
                            >
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>

        <v-card-actions>
            <v-dialog
                v-model="addDialog"
                max-width="450"
                v-if="role=='major'"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="mt-3"
                        v-bind="attrs"
                        v-on="on"
                        color="success"
                        small
                    >
                        Добавить
                    </v-btn>
                </template>

                <v-card class="px-2">
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="addUser"
                    >
                        <v-card-title
                            class="text-h6 card-title"
                        >
                            Новый пользователь
                        </v-card-title>

                        <v-card-text>
                            <v-text-field
                                v-model="email"
                                :rules="[rules.required, rules.email]"
                                label="E-mail"
                                validate-on-blur
                                @keyup.enter="blur"
                            ></v-text-field>

                            <v-alert
                                v-if="addAlert"
                                type="error"
                                dense
                                class="mt-3"
                            >{{ addAlert }}
                            </v-alert>
                        </v-card-text>

                        <v-card-actions class="pb-3">
                            <v-btn
                                color="error"
                                outlined
                                @click="addDialog = false"
                            >
                                Отмена
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                                color="success"
                                @click="addUser"
                                :loading="addLoading"
                            >
                                Создать
                            </v-btn>
                        </v-card-actions>
                    </v-form>

                </v-card>
            </v-dialog>

            <v-dialog
                v-model="deleteDialog"
                max-width="450"
            >
                <v-card>
                    <v-card-title
                        class="text-h6 card-title"
                    >
                        Удалить пользователя {{ userToDelete.email }}?
                    </v-card-title>

                    <v-card-actions class="pb-3">
                        <v-btn
                            color="error"
                            outlined
                            @click="deleteDialog = false"
                        >
                            Отмена
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn
                            color="error"
                            @click="deleteUser"
                            :loading="deleteLoading"
                        >
                            Удалить
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-actions>

    </v-card>

</template>

<script>
import {mapState} from "vuex"

export default {

    data: () => ({
        users: [],
        deleteDialog: false,
        userToDelete: {},
        deleteLoading: false,
        addDialog: false,
        addLoading: false,
        email: '',
        rules: {
            required: value => !!value || 'Укажите e-mail',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Некорректный e-mail'
            }
        },
        valid: false,
        addAlert: ''
    }),


    created() {
        this.getUsers();
    },


    watch: {
        clientId() {
            this.getUsers();
        }
    },


    computed: {
        ...mapState({
            role: state => state.common.user.role,
            clientId: state => state.main.clientId
        }),
    },


    methods: {

        blur(e) {
            e.target.blur();
        },

        openDeleteDialog(user) {
            this.userToDelete = user;
            this.deleteDialog = true;
        },


        getUsers() {
            return this.$store.dispatch('request', {
                controller: 'Management',
                action: 'getUsers'
            }).then(answer => {
                this.users = answer.slice();
            });
        },


        addUser() {
            this.addAlert = '';
            if (!this.$refs.form.validate()) return;
            this.addLoading = true;
            return this.$store.dispatch('request', {
                controller: 'Management',
                action: 'addUser',
                email: this.email
            }).then(() => {
                this.getUsers();
                this.$store.dispatch('alert', {
                    color: 'success',
                    text: 'Пользователь добавлен'
                });
                this.$refs.form.reset();
                this.addDialog = false;
            }).catch(e => {
                this.addAlert = e.message;
            }).finally(() => {
                this.addLoading = false;
            });
        },


        deleteUser() {
            this.deleteLoading = true;
            return this.$store.dispatch('request', {
                controller: 'Management',
                action: 'deleteUser',
                id: this.userToDelete.id
            }).then(answer => {
                if (answer) {
                    this.getUsers();
                    this.$store.dispatch('alert', {
                        color: 'success',
                        text: 'Пользователь удалён'
                    });
                }
            }).finally(() => {
                this.deleteDialog = false;
                this.deleteLoading = false;
            });
        }

    }
}
</script>
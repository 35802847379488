<template>
    <v-container>

        <div class="bg"></div>

        <v-row class="my-5">
            <v-col>
                <users></users>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapState} from 'vuex'
import users from '@/components/management/Users'

export default {
    components: {
        users
    },

    computed: {
        ...mapState({
            role: state => state.common.user.role
        })
    },

    created() {
        if (this.role === 'minor')
            this.$router.push('/');
    },

    watch: {
        role() {
            if (this.role === 'minor')
                this.$router.push('/');
        }
    }
}
</script>

<style>
.card-title.v-card__title {
    word-break: normal;
}
</style>